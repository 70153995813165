import { useMutation, useQueryClient } from "@tanstack/react-query";
import client from "../client";

type AddOfferProductParams = {
    cart_id: string,
    body: {
        product_code: string | undefined,
        quantity: number,
        discount_1?: string,
        discount_2?: string
    }
};

type DeleteOfferProductParams = {
    cart_id: string,
    product_id: number
};

type UpdateProductSpecialParams = {
    cart_id: string,
    product_id: string,
    description?: string,
    uom_id?: string
};

type UpdateOfferProductParams = {
    cart_id: number,
    product_id: number,
    body: {
        quantity: number,
        discount_1?: string,
        discount_2?: string,
        manualNetPrice?: string,
    }
};

type ReplaceProductParams = {
    cart_id: string,
    product_id: string,
    body: {
        quantity: number,
        product_code: string
    }
};

type OfferIdParam = {
    cart_id: number
};

export default function useOfferItemsMutations() {
    const queryClient = useQueryClient();

    // ADD PRODUCT
    const addOfferProduct = useMutation(async (productInfo: AddOfferProductParams) => {
        return await client.post(`offers/${productInfo.cart_id}/items`, productInfo.body);
    }, { onSuccess: () => { queryClient.invalidateQueries(["offer"]); } });

    // DELETE PRODUCT
    const deleteOfferProduct = useMutation(async (params: DeleteOfferProductParams) => {
        return await client.delete(`/offers/${params.cart_id}/items/${params.product_id}`);
    }, { onSuccess: () => { queryClient.invalidateQueries(["offer"]); } });

    // UPDATE PRODUCT
    const updateOfferProduct = useMutation(async (params: UpdateOfferProductParams) => {
        return await client.put(`/offers/${params.cart_id}/items/${params.product_id}`, params.body);
    }, { onSuccess: () => { queryClient.invalidateQueries(["offer"]); } });

    // DELETE ALL PRODUCTS
    const deleteAllOfferProducts = useMutation(async (params: OfferIdParam) => {
        return await client.delete(`/offers/${params.cart_id}/items`);
    }, { onSuccess: () => { queryClient.invalidateQueries(["offer"]); } });

    // UPDATE DESCRIPTION
    const updateProductSpecial = useMutation(async (params: UpdateProductSpecialParams) => {
        return await client.put(`/offers/${params.cart_id}/items/${params.product_id}/special`, params);
    }, { onSuccess: () => { queryClient.invalidateQueries(["offer"]); } });

    // REPLACE PRODuCT
    const replaceOfferProduct = useMutation(async (params: ReplaceProductParams) => {
        return await client.post(`/offers/${params.cart_id}/alternative-products/${params.product_id}`, params.body);
    }, { onSuccess: () => { queryClient.invalidateQueries(["offer"]); } });

    return { addOfferProduct, deleteOfferProduct, updateOfferProduct, deleteAllOfferProducts, updateProductSpecial, replaceOfferProduct };
}