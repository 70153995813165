import { Tooltip } from '@reach/tooltip';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { FaPencilAlt } from 'react-icons/fa';
import Select from 'react-select';
import useUnitOfMeasures from '../../api/unit-of-measures/useUnitOfMeasures';
import { UpdateProductSpecialParams } from '../../api/carts/useCartItemsMutations';

type Props = {
    cart_id: string;
    item_id: string;
    item_code: string;
    item_uom_description: string;
    handleUpdateSpecial: (params: UpdateProductSpecialParams) => Promise<void>;
}

const styles = {
    container: (provided: any, state: any) => ({
        ...provided,
        pointerEvents: "auto",
        cursor: `${state.isDisabled ? "not-allowed" : null}`,
        fontWeight: 300,
        height: "30px",
    }),
    control: (_: any, state: any) => ({
        border: `${state.isFocused ? "2px solid #035ECC" : "1px solid rgb(229 231 235)"}`,
        borderRadius: `${state.isFocused ? "3px" : null}`,
        display: "flex",
        justifyContent: "space-between",
        height: "30px",
        backgroundColor: `${state.isDisabled ? "#fafafa" : "#FFF"}`,
    }),
    SelectContainer: () => ({
        border: "1px solid rgb(229 231 235)",
        display: "flex",
        justifyContent: "space-between",
        padding: "3px 0px",
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        display: "flex",
        alignItems: "center",
        flexWrap: "nowrap",
        padding: "0px 0.4rem",
        textOverflow: "ellipsis",
        maxWidth: "90%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: "#000"
    }),
    singleValue: () => ({
        display: "flex",
        alignItems: "center",
        marginLeft: "3px",
    }),
    placeholder: (_: any, state: any) => ({
        display: `${state.isFocused && "none"}`,
        marginLeft: "3px",
        color: `${state.isDisabled ? "#A7A7A7" : "#9CA3AF"}`,
        fontSize: "14px",
        fontWeight: 300
    }),
    menu: (provided: any) => ({
        ...provided,
        borderRadius: "0px",
        padding: "5px"
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        color: "#202020",
        // color: state.isSelected ? 'white' : '#202020',
        display: "flex",
        padding: "5px 0px",
        backgroundColor: state.isDisabled
            ? undefined
            // : state.isSelected
            //     ? 'black'
            : state.isFocused
                ? "#E5E7EB"
                : state.isActive ?
                    "#202020" : undefined,
        opacity: state.isDisabled ? 0.3 : 1,
        cursor: state.isDisabled ? "not-allowed" : "pointer",
    }),
    loadingMessage: (provided: any) => ({
        ...provided,
        color: "#202020"
    }),
    noOptionsMessage: (provided: any) => ({
        ...provided,
        color: "#202020"
    }),
    clearIndicator: (provided: any) => ({
        ...provided,
        cursor: "pointer",
        color: "black"
    })
};

export const EditableUomDescription = ({ cart_id, item_id, item_code, item_uom_description, handleUpdateSpecial }: Props) => {
    const { t } = useTranslation();

    // state
    const [uoms, setUoms] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    // react query
    const { unitOfMeasures } = useUnitOfMeasures({ 'filter[special]': true }, isEditing);

    useEffect(() => {
        if (unitOfMeasures?.data?.length > 0) {
            const uoms = unitOfMeasures.data.map((uom: any) => {
                return {
                    value: uom.id,
                    label: uom.trans.description
                }
            });
            setUoms(uoms);
        }
    }, [unitOfMeasures]);

    const handleUpdateUom = async (inputValue: any) => {
        await handleUpdateSpecial({ cart_id, product_id: item_id, uom_id: inputValue.value });

        setIsEditing(false);
    }

    if (isEditing) {
        return (
            <div className='w-20 mt-1'>
                <Select
                    options={uoms}
                    styles={styles}
                    placeholder='uom'
                    noOptionsMessage={() => t("Nessun risultato")}
                    components={{ IndicatorSeparator: () => null }}
                    onChange={handleUpdateUom}
                    onBlur={() => setIsEditing(false)}
                    menuPlacement="auto"
                    menuPosition="fixed"
                    autoFocus
                />
            </div>
        );
    } else {
        if (item_code === "SPECIAL") {
            return (
                <div className="flex items-center gap-x-1 cursor-pointer" onClick={() => setIsEditing(true)}>
                    <span className='mt-1 text-xs opacity-50'>{item_uom_description}</span>
                    <Tooltip
                        label={t("Inserisci unità di misura")}
                        style={{
                            backgroundColor: "#F1F5F6",
                            borderRadius: "3px",
                            fontWeight: "300",
                            marginRight: "20px",
                            zIndex: 50
                        }}
                    >
                        <span>
                            <FaPencilAlt />
                        </span>
                    </Tooltip>
                </div>
            );
        } else {
            return <span className='mt-1 text-xs opacity-50'>{item_uom_description}</span>
        }
    }
}
