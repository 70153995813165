import { useTranslation } from "react-i18next"
import { Button } from "../../../Button";
import { useState } from "react";

type ReplaceProductProps = {
    alternativeItems: any;
    selectedAlternative: any;
    setSelectedAlternative: any;
    alternativeItemQty: any;
    setAlternativeItemQty: any;
    closeModal: () => void;
    cartId: any;
    productId: any;
    replaceProduct: any;
};

export const ReplaceProduct = ({ alternativeItems, selectedAlternative, setSelectedAlternative, alternativeItemQty, setAlternativeItemQty, closeModal, replaceProduct, cartId, productId }: ReplaceProductProps) => {
    const { t } = useTranslation();

    // states
    const [error, setError] = useState({ alternativeItem: "", qty: "" });

    const handleSelectedAlternativeProduct = (alternativeItem: any) => {
        setSelectedAlternative(alternativeItem);
        setAlternativeItemQty(parseInt(alternativeItem?.minsellable));
    };

    const handleSubmit = async () => {
        if (!selectedAlternative) {
            setError({ ...error, alternativeItem: t("seleziona un prodotto alternativo") });
            return;
        } else {
            setError((prevErrors) => { return { ...prevErrors, alternativeItem: "" }; });
        }

        if (alternativeItemQty < parseInt(selectedAlternative.minsellable) || alternativeItemQty % selectedAlternative.minsellable !== 0 || alternativeItemQty === 0) {
            setError((prevErrors) => { return { ...prevErrors, qty: t("la quantità non rispetta il minimo vendibile") }; });
            return;
        }

        const body = {
            product_code: selectedAlternative?.code,
            quantity: alternativeItemQty
        };

        await replaceProduct.mutateAsync({ cart_id: cartId, product_id: productId, body });

        closeModal();
    };



    return <>
        <div className="bg-neutral-weaker p-6">
            {
                alternativeItems?.length > 0 ?
                    <>
                        {/* ITEMS TABLE */}
                        <form>
                            <fieldset className='mb-0'>
                                <legend className='pb-1'>{t("Sostituisci il prodotto con un prodotto correlato")}</legend>
                                <div className={`border mb-4 ${error.alternativeItem.length > 0 ? "border-rose-500" : "bg-white"}`}>
                                    {
                                        alternativeItems && alternativeItems.map((alternativeItem: any) => (
                                            <label key={alternativeItem.code} className='flex items-center p-2 w-full cursor-pointer border-b last:border-b-0 space-x-2'>
                                                <div className='w-10 h-10 flex justify-center items-center'>
                                                    <input
                                                        type="radio"
                                                        name='change_product'
                                                        onChange={() => handleSelectedAlternativeProduct(alternativeItem)}
                                                        required
                                                    />
                                                </div>
                                                <img src={`https://resources.rothoblaas.com/eservice-images/product/${alternativeItem.code}`} className="zoom max-w-[50px] max-h-[50px] m-auto" alt='' />
                                                <div className='text-xs space-y-1'>
                                                    <div>{alternativeItem.code}</div>
                                                    <div className='font-light'>{alternativeItem.description}</div>
                                                </div>
                                            </label>
                                        ))
                                    }
                                    {/* </div> */}
                                </div>

                                {error && <p className='text-rose-500 text-xs'>{error.alternativeItem}</p>}

                                {/* QTY INPUT */}
                                <div className='mb-4'>
                                    <label htmlFor="change_item_qty">{t("Quantità")}</label>
                                    <input
                                        className={`${error.qty.length > 0 && "border-rose-500"}`}
                                        // ref={alternativeQtyInput}
                                        value={alternativeItemQty}
                                        onChange={(e) => setAlternativeItemQty(e.target.value)}
                                        type="number"
                                        min={selectedAlternative?.minsellable}
                                        step={selectedAlternative?.minsellable}
                                        disabled={!selectedAlternative}
                                        required
                                    />
                                </div>

                                {error && <p className='text-rose-500 text-xs'>{error.qty}</p>}

                            </fieldset>
                        </form>

                    </>
                    :
                    <div className='font-light text-sm'>
                        {t("Nessun articolo alternativo disponibile per l'articolo selezionato")}
                    </div>
            }
        </div>

        <div className='space-x-4'>
            <Button variant={Button.variant.primary} type="button" onClick={handleSubmit} isDisabled={!selectedAlternative}>{t("Conferma")}</Button>
            <Button variant={Button.variant.text} type="button" onClick={closeModal}>Annulla</Button>
        </div>
    </>
}
