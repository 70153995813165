import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import { useAuthContext } from "../../../hooks/use-context/useAuthContext";
import useMarginCalculationMethods from "../../../hooks/useMarginCalculationMethods";

type searchParams = {
    "filter[order_number]"?: string,
    "filter[customer_code]"?: string,
    "filter[min_date]"?: string,
    "filter[max_date]"?: string,
    "sort"?: string
};

type PriceListTableProps = {
    items: any,
    customer: any,
    setSearchParams: React.Dispatch<React.SetStateAction<searchParams>>,
    selectedCustomer: any,
    showNetPrice: boolean
};

export const PriceListTable = ({ items, customer, selectedCustomer, showNetPrice }: PriceListTableProps) => {
    const { user, roles } = useAuthContext();
    const { t } = useTranslation();
    const { semiramisRound, calculateNetPrice } = useMarginCalculationMethods();

    // state
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    // table data
    const data = useMemo(
        () => {
            return items;
        },
        [items]
    );

    // SET HIDDEN COLUMNS
    useEffect(() => {
        if (showNetPrice) {
            setHiddenColumns(["discount_1", "discount_2", "pricevalue"]);
        } else {
            setHiddenColumns([]);
        }
    }, [items, customer, showNetPrice]);

    const columns: Array<Column<any>> = useMemo(
        () => [
            {
                Header: t("Codice articolo").toString(),
                className: "border-b p-4",
                Cell: (props: any) => {
                    if (!props.row.original.itemInfo?.code) return "-";

                    return props.row.original.itemInfo?.code;
                }
            },
            {
                Header: t("Gruppo prodotto").toString(),
                className: "border-b p-4",
                Cell: (props: any) => {
                    return props.row.original.itemInfo?.priceclassification;
                }
            },
            {
                Header: t("Descrizione").toString(),
                className: "border-b p-4",
                Cell: (props: any) => {
                    return props.row.original.itemInfo?.description;
                }
            },
            {
                Header: t("Pieces per package").toString(),
                className: "border-b p-4",
                Cell: (props: any) => {
                    return `${parseInt(props.row.original.itemInfo?.piecesperpackage)} ${props.row.original.itemInfo?.piecesperpackageuom}`;
                }
            },
            {
                Header: t("Prezzo listino cliente ({{currency}})", { currency: items?.length ? items[0]?.itemInfo?.currency : "-" }).toString(),
                className: "border-b p-4",
                accessor: "pricevalue",
                disableSortBy: true,
                Cell: (props: any) => {
                    if (!props.row.original.item?.pricevalue) return "-";

                    const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 5, maximumFractionDigits: 5 });

                    const price = semiramisRound(parseFloat(props.row.original.item.pricevalue.toString()), 5);

                    return numberFormat.format((price));
                }
            },
            {
                Header: t("Sconto %").toString(),
                className: "border-b p-4",
                accessor: "discount_1",
                disableSortBy: true,
                Cell: (props: any) => {
                    if (!props.row.original.itemSC1?.pricevalue) return "-";

                    const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                    return numberFormat.format(props.row.original.itemSC1?.pricevalue);
                }
            },
            {
                Header: t("Sconto 2 %").toString(),
                className: "border-b p-4",
                accessor: "discount_2",
                disableSortBy: true,
                Cell: (props: any) => {
                    if (!props.row.original?.itemSC2?.pricevalue) return "-";

                    const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                    return numberFormat.format(props.row.original?.itemSC2?.pricevalue);
                }
            },
            {
                Header: t("Prezzo scontato cliente unitario ({{currency}})", { currency: items?.length ? items[0]?.itemInfo?.currency : "-" }).toString(),
                className: "border-b p-4",
                Cell: (props: any) => {
                    if (!props.row.original.item?.pricevalue) return "-";

                    let result;
                    const qty = parseInt(props.row.original.itemInfo.minsellable);
                    const discount_1 = props.row.original?.itemSC1?.pricevalue ?? 0;
                    const discount_2 = props.row.original?.itemSC2?.pricevalue ?? 0;
                    const price = props.row.original.item.pricevalue;

                    const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 5, maximumFractionDigits: 5 });

                    const netPrice = calculateNetPrice(price, qty, discount_1, discount_2, 5);

                    result = numberFormat.format(netPrice);

                    return result;
                }
            },
            {
                Header: t("Peso netto unitario kg").toString(),
                className: "border-b p-4",
                Cell: (props: any) => {

                    const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 4 });
                    const result = numberFormat.format(props.row.original.itemInfo?.netweight);

                    return result;
                }
            },
            {
                Header: t("HS Code").toString(),
                className: "border-b p-4",
                Cell: (props: any) => {
                    return props.row.original.itemInfo?.hscode ?? "-";
                }
            },
            {
                Header: t("EAN Code").toString(),
                className: "border-b p-4",
                Cell: (props: any) => {
                    return props.row.original.itemInfo?.barcode ?? "-";
                }
            },
            {
                Header: t("Indicatory qty pal").toString(),
                className: "border-b p-4",
                Cell: (props: any) => {
                    return props.row.original.itemInfo?.palletquantity ?? "-";
                }
            },
        ],
        [t, hiddenColumns]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page
    } = useTable({
        columns,
        data,
        manualSortBy: true,
        disableSortRemove: true,
        initialState: { hiddenColumns: hiddenColumns, pageSize: 10 },
        dateFormat
    }, useSortBy, usePagination);

    return (
        <>
            <table {...getTableProps()} className="border w-full text-xs font-light">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className="border-b">
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className="p-4 text-left"
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td
                                            {...cell.getCellProps({ className: (cell.column as any).className })}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
};
