import { useTranslation } from "react-i18next";
import { Title } from "../components/Title";
import { useLocation, useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { ImFilesEmpty } from "react-icons/im";
import Pagination from "../components/Pagination";
import { OffersTable } from "../components/offers/OffersTable";
import OffersSearchBar, { OfferSearchParams } from "../components/offers/OffersSearchBar";
import { Loader } from "../components/Loader";
import useOffers from "../api/offers/useOffers";
import SubRowAsync from "../components/carts/SubRowAsync";
import { Section } from "../components/Section";
import { Slide, ToastContainer } from "react-toastify";

type LocationState = {
    successMsg?: string
};

const Offers = () => {
    const location = useLocation();
    const locationState = location.state as LocationState;
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const initialPage = parseInt(searchParams.get("page") || "1", 10);

    // state
    const [successMsg, setSuccessMsg] = useState<string | null>(null);
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [params, setParams] = useState<OfferSearchParams>({});

    const { offers, isLoading, isFetching } = useOffers({ page: currentPage, ...params });

    // SUB ROW RENDER FUNCTION
    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <SubRowAsync row={row} endpoint={"offers"} />
        ),
        []
    );

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };


    useEffect(() => {
        if (locationState?.successMsg) {
            setSuccessMsg(locationState.successMsg);
        }
    }, [locationState]);

    // reset page when params change
    useEffect(() => {
        const tmpParams: any = {};

        searchParams.forEach((value: any, key: any) => {
            tmpParams[key] = value;
        });

        setParams(tmpParams);

        const pageFromParams = parseInt(searchParams.get("page") || "1", 10);
        if (pageFromParams !== currentPage) {
            setCurrentPage(pageFromParams);
        }
    }, [searchParams]);

    return (
        <div className="py-10 px-8 2xl:px-28">
            <Title variant={Title.variant.primary}>{t("Le mie offerte")}</Title>

            {(isLoading || isFetching) && <Loader />}

            <Section title={"Cerca offerta"} className="mb-10">
                <OffersSearchBar setSearchParams={setSearchParams} />
            </Section>

            <div>
                {
                    offers?.data?.length > 0 ?
                        <>
                            <ToastContainer
                                position="top-center"
                                autoClose={3000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="colored"
                                transition={Slide}
                            />

                            <OffersTable
                                items={offers?.data}
                                setParams={setParams}
                                renderRowSubComponent={renderRowSubComponent}
                            />
                            <Pagination
                                currentPage={currentPage}
                                totalCount={offers?.meta.total}
                                pageSize={10}
                                onPageChange={handlePageChange}
                            />
                        </>
                        :
                        <div className="h-96 flex flex-col items-center justify-center space-y-6">
                            <span className="text-9xl text-gray-200"><ImFilesEmpty /></span>
                            <p className="text-2xl text-gray-600">{t("Nessuna offerta trovata")}</p>
                        </div>
                }
            </div>
        </div>
    );
};

export default Offers;
