import { useEffect, useMemo, useState } from "react";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import { Item } from "../../interfaces/Item";
import { FaTrash } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../hooks/use-context/useAuthContext";

type MarginCalculationProps = {
    items: Item[],
    updateTableData: (rowIndex: any, columnId: any, value: any) => void
    deleteRow: (event: React.MouseEvent<Element, MouseEvent>) => void,
    skipPageReset: boolean,
    semiramisRound: any,
    calculateNetPrice: any,
    getCurrencyDecimals: any,
    hiddenColums: any,
    handleNumberDecimals: any
    selectedCustomer: any,
    isTableValid: any
};

// create editable cell that update tool margini discounts & quantity on parent
const EditableCellQty = ({ value: initialValue, row: { index }, column: { id }, updateTableData, cell, isTableValid }: any) => {
    const { t } = useTranslation();

    // We need to keep and update the state of the cell normally
    const minsellable = parseInt(cell.row.original.minsellable);
    const [value, setValue] = useState(initialValue);

    const onChange = (e: any) => {
        setValue(parseInt(e.target.value));
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateTableData(index, id, value, minsellable);
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);


    return (
        <>
            <div className='flex justify-center items-center gap-1'>
                <input className={!isTableValid.includes(index) ? "w-16 p-1" : "w-16 p-1 border-rose-500 outline-none rounded"} type="number" min={minsellable} step={minsellable} value={isNaN(value) ? "" : value} onChange={onChange} onBlur={onBlur} />
                {isTableValid.includes(index) &&
                    <div role="alert" tabIndex={1} className='relative inline-block text-red-500'>
                        <span className='peer cursor-pointer text-xl'><IoIosWarning /> </span>
                        <span className='hidden peer-hover:block peer-focus:block z-20 absolute top-full mt-2 right-2 translate-x-2/4 w-36 p-2 bg-red-200 rounded shadow'>{t("La quantità non rispetta il minimo vendibile")}</span>
                    </div>
                }
            </div>
            {<span className="block mt-1 text-xs text-disabled">{cell.row.original.uom_description}</span>}
        </>
    );
};

const EditableCellSc = ({ value: initialValue, row: { index }, column: { id }, updateTableData, cell }: any) => {

    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(initialValue);

    const onChange = (e: any) => {
        setValue(e.target.value);
    };

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        updateTableData(index, id, value);
        setValue((prevValue: any) => {
            if (prevValue >= 100) {
                const num = 99;
                return num.toFixed(2);
            } else if (prevValue < 0 || isNaN(prevValue) || prevValue === "") {
                const num = 0;
                return num.toFixed(2);
            }
            const n = parseFloat(prevValue).toFixed(2);
            return n;
        });
    };

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const discount_type = cell.row.original.pricediscounttype;
    const disabled = discount_type === "110" ? true : false;

    return <input id='discount' className={`${value >= 0 && value < 100 ? "w-16 p-1" : "w-16 p-1 border-rose-500 outline-none rounded"} ${disabled ? "cursor-not-allowed" : ""}`} type="number" min={0} max={99} step={"1"} value={isNaN(value) ? "" : value} onChange={onChange} onBlur={onBlur} disabled={disabled} />;
};

// Table component -----------------------
const MarginCalculationTable: React.FC<MarginCalculationProps> = ({ items, hiddenColums, updateTableData, deleteRow, skipPageReset, semiramisRound, calculateNetPrice, getCurrencyDecimals, selectedCustomer, isTableValid }) => {
    const { t } = useTranslation();
    const { user } = useAuthContext();

    // table data
    const data = useMemo(
        () => {
            return items;
        },
        [items]
    );

    const columns: Array<Column<Item>> = useMemo(
        () => [
            {
                Header: selectedCustomer.organization_id,
                className: "p-4 border-b border-gray-200",
                columns: [
                    {
                        Header: t("Codice articolo").toString(),
                        accessor: "code",
                        className: "text-center p-4",
                        Cell: (props) => {
                            return (
                                <>
                                    <div className='mx-auto mb-1 text-center text-2xl'>
                                        <img src={`https://resources.rothoblaas.com/eservice-images/product/${props.cell.row.original.code}`} className="zoom max-w-[50px] max-h-[50px] m-auto" alt='' />
                                    </div>
                                    <span className={"block text-center"}>{props.cell.row.original.code.toUpperCase()}</span>
                                </>
                            );
                        },
                        Footer: () => {
                            return t("Totali").toString();
                        }
                    },
                    {
                        Header: t("Descrizione").toString(),
                        accessor: "description",
                        className: "text-left p-4",
                        Cell: (props) => {
                            return (
                                <>
                                    <span className="block font-bold">{props.cell.row.original.name}</span> {props.cell.row.original.description} ({parseInt(props.cell.row.original.piecesperpackage, 10)} {props.cell.row.original.piecesperpackageuom})
                                </>
                            );
                        }
                    },

                    {
                        Header: t("Quantità").toString(),
                        accessor: "qty",
                        className: "text-left p-4",
                        Cell: EditableCellQty
                    },
                    {
                        Header: t("Sconto 1 %").toString(),
                        accessor: "discount_1",
                        disableSortBy: true,
                        className: "text-left p-4",
                        Cell: EditableCellSc
                    },
                    {
                        Header: t("Sconto 2 %").toString(),
                        accessor: "discount_2",
                        disableSortBy: true,
                        className: "text-left p-4",
                        Cell: EditableCellSc
                    },
                    {
                        Header: t("Margine %").toString(),
                        accessor: "margine_netto",
                        className: "text-right p-4 bg-tertiary",
                        Cell: (props) => {
                            const qty = props.cell.row.original.qty;
                            const discount_1 = props.cell.row.original.discount_1;
                            const discount_2 = props.cell.row.original.discount_2;
                            const price = props.cell.row.original.pricevalue;
                            const purchaseprice = props.cell.row.original.purchaseprice;

                            const netPrice = calculateNetPrice(price, qty, discount_1, discount_2, getCurrencyDecimals(items[0].currency));
                            const prezzoAcquisto = parseFloat(purchaseprice);

                            const totAcquisto = prezzoAcquisto * qty;
                            const totVendita = netPrice * qty;

                            const margine = semiramisRound((totVendita - totAcquisto) / totVendita * 100, 2);

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                            const result = numberFormat.format(margine);

                            return (
                                <>
                                    {<span>{result}</span>}
                                </>
                            );
                        },
                        Footer: (info) => {
                            const data = info.data;
                            let sumTotAcquisto = 0;
                            let sumTotVendita = 0;

                            for (let i = 0; i < data.length; i++) {
                                const qty = data[i].qty;
                                const discount_1 = data[i].discount_1;
                                const discount_2 = data[i].discount_2;
                                const price = data[i].pricevalue;
                                const purchaseprice = data[i].purchaseprice;

                                const netPrice = calculateNetPrice(price, qty, discount_1, discount_2, getCurrencyDecimals(items[0].currency));
                                const prezzoAcquisto = parseFloat(purchaseprice);

                                sumTotAcquisto += prezzoAcquisto * qty;
                                sumTotVendita += netPrice * qty;
                            }

                            const margine = semiramisRound((sumTotVendita - sumTotAcquisto) / sumTotVendita * 100, 2);
                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                            const result = numberFormat.format(margine);
                            return result;
                        }
                    },
                    {
                        Header: t("Margine ({{currency}})", { currency: items[0].currency }).toString(),
                        // accessor: 'totale_scontato',
                        className: "text-right p-4 bg-tertiary",
                        Cell: (props: any) => {
                            const qty = props.cell.row.original.qty;
                            const discount_1 = props.cell.row.original.discount_1;
                            const discount_2 = props.cell.row.original.discount_2;
                            const price = props.cell.row.original.pricevalue;
                            const netPrice = calculateNetPrice(price, qty, discount_1, discount_2, getCurrencyDecimals(items[0].currency));
                            const totVendita = netPrice * qty;

                            const prezzoAcquisto = parseFloat(props.cell.row.original.purchaseprice);

                            const calc = semiramisRound(totVendita - (prezzoAcquisto * qty), getCurrencyDecimals(items[0].currency));

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: getCurrencyDecimals(items[0].currency), maximumFractionDigits: getCurrencyDecimals(items[0].currency) });
                            const result = numberFormat.format(calc);

                            return (
                                <>
                                    {<span>{result}</span>}
                                </>
                            );
                        },
                        Footer: (info) => {
                            const data = info.data;

                            let sumCalc = 0;
                            for (let i = 0; i < data.length; i++) {
                                const qty = data[i].qty;
                                const discount_1 = data[i].discount_1;
                                const discount_2 = data[i].discount_2;
                                const price = data[i].pricevalue;
                                const netPrice = calculateNetPrice(price, qty, discount_1, discount_2, getCurrencyDecimals(items[0].currency));
                                const totVendita = netPrice * qty;

                                const prezzoAcquisto = parseFloat(data[i].purchaseprice);

                                const calc = semiramisRound(totVendita - (prezzoAcquisto * qty), getCurrencyDecimals(items[0].currency));

                                sumCalc += calc;

                            }

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: getCurrencyDecimals(items[0].currency), maximumFractionDigits: getCurrencyDecimals(items[0].currency) });
                            const result = numberFormat.format(sumCalc);

                            return result;
                        }
                    },
                    {
                        Header: t("Prezzo listino cliente ({{currency}})", { currency: items[0].currency }).toString(),
                        accessor: "pricevalue",
                        disableSortBy: true,
                        className: "text-right p-4",
                        Cell: (props) => {
                            let price = props.row.original.pricevalue ? parseFloat(props.row.original.pricevalue.toString()) : 0;

                            if (isNaN(price)) {
                                price = 0;
                            }
                            price = semiramisRound(price, 5);

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 5 });
                            const result = numberFormat.format(price);

                            return (
                                <>
                                    <span>{result}</span>
                                </>
                            );
                        }
                    },
                    {
                        Header: t("Prezzo scontato cliente unitario ({{currency}})", { currency: items[0].currency }).toString(),
                        accessor: "net_price",
                        className: "text-right p-4",
                        Cell: (props) => {
                            const qty = props.cell.row.original.qty;
                            const discount_1 = props.cell.row.original.discount_1;
                            const discount_2 = props.cell.row.original.discount_2;
                            const price = props.cell.row.original.pricevalue;
                            //console.warn("PRICE HANDLE");
                            //console.log(price);
                            const netPrice = calculateNetPrice(price, qty, discount_1, discount_2, getCurrencyDecimals(items[0].currency));
                            //console.log(netPrice);
                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 5 });
                            const result = numberFormat.format(netPrice);

                            return (
                                <>
                                    <span>{result}</span>
                                </>
                            );
                        }
                    },
                    {
                        Header: t("Totale scontato ({{currency}})", { currency: items[0].currency }).toString(),
                        accessor: "totale_vendita",
                        className: "text-right p-4",
                        Cell: (props) => {
                            const qty = props.cell.row.original.qty;
                            const discount_1 = props.cell.row.original.discount_1;
                            const discount_2 = props.cell.row.original.discount_2;
                            const price = props.cell.row.original.pricevalue;
                            //console.log('---------------------');
                            //console.log(price);
                            const netPrice = calculateNetPrice(price, qty, discount_1, discount_2, getCurrencyDecimals(items[0].currency));
                            //console.log(netPrice);
                            const totVendita = semiramisRound(netPrice * qty, getCurrencyDecimals(items[0].currency));
                            //console.log(totVendita);
                            // let totVenditaCd = getCurrencyDecimals(items[0].currency);
                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: getCurrencyDecimals(items[0].currency), maximumFractionDigits: getCurrencyDecimals(items[0].currency) });
                            const result = numberFormat.format(totVendita);
                            //console.log(result);
                            return (
                                <>
                                    <span>{result}</span>
                                </>
                            );
                        },
                        Footer: (info) => {
                            let totalSum = 0;
                            const data = info.data;
                            for (let i = 0; i < data.length; i++) {
                                const netPrice = calculateNetPrice(data[i].pricevalue, data[i].qty, data[i].discount_1, data[i].discount_2, getCurrencyDecimals(items[0].currency));
                                const totVendita = netPrice * data[i].qty;
                                totalSum += totVendita;
                            }

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: getCurrencyDecimals(items[0].currency), maximumFractionDigits: getCurrencyDecimals(items[0].currency) });
                            const result = numberFormat.format(totalSum);

                            return result;
                        }
                    },
                    {
                        Header: () => {
                            // console.log(t('Prezzo acquisto info').toString());
                            // console.log(t('Prezzo acquisto info').toString().split('\\n').join('#').split("\n").join('#').split('#'));
                            return <>
                                <>{t("Prezzo acquisto ({{currency}})", { currency: items[0].currency }).toString()}</>
                                <div className="font-bold mt-2 group relative block py-0 px-0 underline duration-300">
                                    Info
                                    <div className="absolute hidden group-hover:block -top-2 -right-3 translate-x-full w-56 px-4 py-4 bg-gray-700 rounded-lg text-left text-white text-sm">
                                        <div className="flex-none">{t("Prezzo acquisto info").toString().split("\\n").join("#").split("\n").join("#").split("#").map((line, i) => (
                                            <span className="mb-3 block" key={i}>{line}</span>
                                        ))}</div>
                                    </div>
                                </div>
                            </>;
                        },
                        accessor: "purchaseprice",
                        className: "text-right p-4",
                        Cell: (props) => {
                            let prezzoAcquisto = parseFloat(props.cell.row.original.purchaseprice);
                            prezzoAcquisto = semiramisRound(prezzoAcquisto, 5);

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 5 });
                            const result = numberFormat.format(prezzoAcquisto);
                            return result;
                        }
                    },
                    {
                        Header: t("Costo doganale HS % (incluso)").toString(),
                        accessor: "perchscode",
                        className: "text-right p-4",
                        Cell: (props) => {
                            const hs_cost = semiramisRound(parseFloat(props.row.original.perchscode), 2);
                            // console.log(props);

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                            const result = numberFormat.format(hs_cost);

                            return result;
                        }
                    },
                    {
                        Header: t("Peso netto unitario kg").toString(),
                        accessor: "netweight",
                        className: "text-right p-4",
                        Cell: (props) => {
                            const netWeight = semiramisRound(parseFloat(props.cell.row.original.netweight), 4);

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 4 });
                            const result = numberFormat.format(netWeight);
                            return result;
                        },
                    },
                    {
                        Header: t("Peso netto totale kg").toString(),
                        className: "text-right p-4",
                        Cell: (props: any) => {
                            const qty = props.cell.row.original.qty;
                            const netWeight = parseFloat(props.cell.row.original.netweight);
                            const totRowWeight = semiramisRound(qty * netWeight, 4);

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 4 });
                            const result = numberFormat.format(totRowWeight);

                            return result;
                        },
                        Footer: (info) => {
                            let totalSum = 0;
                            const data = info.data;
                            for (let i = 0; i < data.length; i++) {
                                const qty = data[i].qty;
                                const netWeight = parseFloat(data[i].netweight);
                                const totRowWeight = semiramisRound(qty * netWeight, 4);
                                totalSum += totRowWeight;
                            }

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 4 });
                            const result = numberFormat.format(totalSum);

                            return result;
                        }
                    },
                ],
                Footer: ""
            },
            {
                Header: "IT001",
                columns: [
                    {
                        Header: t("Prezzo netto di vendita IT-SUB ({{currency}})", { currency: "EUR" }).toString(),
                        accessor: "materialpriceEUR",
                        className: "text-right p-4",
                        Cell: (props) => {
                            const material_price_eur = parseFloat(props.row.original.materialpriceEUR);

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 5 });
                            const result = numberFormat.format(material_price_eur);

                            return (
                                <>
                                    <span>{result}</span>
                                </>
                            );
                        }
                    },
                    {
                        Header: t("Margine IT-SUB %").toString(),
                        id: "Margin",
                        className: "text-right p-4 bg-table-secondary border-b border-gray-100",
                        Cell: (props: any) => {
                            const material_price_eur = parseFloat(props.row.original.materialpriceEUR);
                            const purchase_price_hq = parseFloat(props.row.original.purchaseprice_hq);

                            const calc = semiramisRound(((material_price_eur - purchase_price_hq) / material_price_eur) * 100, 2);

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                            const result = numberFormat.format(calc);

                            return (
                                <>
                                    {<span>{result}</span>}
                                </>
                            );
                        },
                        Footer: (info) => {
                            const data = info.data;
                            let sum_material_price_eur = 0;
                            let sum_purchase_price_hq = 0;

                            for (let i = 0; i < data.length; i++) {
                                const material_price_eur = parseFloat(data[i].materialpriceEUR);
                                const purchase_price_hq = parseFloat(data[i].purchaseprice_hq);

                                sum_material_price_eur += material_price_eur;
                                sum_purchase_price_hq += purchase_price_hq;
                            }

                            const calc = semiramisRound(((sum_material_price_eur - sum_purchase_price_hq) / sum_material_price_eur) * 100, 2);

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                            const result = numberFormat.format(calc);

                            return result;
                        }
                    },
                    {
                        Header: t("Margine totale IT-SUB ({{currency}})", { currency: "EUR" }).toString(),
                        id: "Total_margin",
                        className: "text-right p-4 bg-table-secondary border-b border-gray-100",
                        Cell: (props: any) => {
                            const qty = props.row.original.qty;
                            const material_price_eur = parseFloat(props.row.original.materialpriceEUR);
                            const purchase_price_hq = parseFloat(props.row.original.purchaseprice_hq);

                            const calc = semiramisRound((material_price_eur - purchase_price_hq) * qty, getCurrencyDecimals(items[0].currency));

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: getCurrencyDecimals(items[0].currency), maximumFractionDigits: getCurrencyDecimals(items[0].currency) });
                            const result = numberFormat.format(calc);

                            return (
                                <>
                                    {<span>{result}</span>}
                                </>
                            );
                        },
                        Footer: (info) => {
                            const data = info.data;
                            let sumCalc = 0;

                            for (let i = 0; i < data.length; i++) {
                                const qty = data[i].qty;
                                const material_price_eur = parseFloat(data[i].materialpriceEUR);
                                const purchase_price_hq = parseFloat(data[i].purchaseprice_hq);

                                const calc = semiramisRound((material_price_eur - purchase_price_hq) * qty, getCurrencyDecimals(items[0].currency));
                                sumCalc += calc;
                            }

                            const numberFormat = new Intl.NumberFormat(selectedCustomer?.language_id, { minimumFractionDigits: getCurrencyDecimals(items[0].currency), maximumFractionDigits: getCurrencyDecimals(items[0].currency) });
                            const result = numberFormat.format(sumCalc);

                            return result;
                        }
                    },
                ],
                Footer: ""
            },
            {
                Header: " ",
                columns: [
                    {
                        Header: " ",
                        className: "text-left p-4",
                        Cell: ({ row: { index } }: any) => {
                            return (
                                <FaTrash
                                    className="cursor-pointer hover:text-secondary"
                                    onClick={() => deleteRow(index)}
                                />
                            );
                        }
                    },
                ],
                Footer: ""
            }
        ],
        [t, deleteRow, calculateNetPrice, getCurrencyDecimals, items, selectedCustomer?.organization_id, semiramisRound, user?.language_id]
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        footerGroups,
    } = useTable({ columns, data, initialState: { hiddenColumns: hiddenColums, pageSize: 150 }, EditableCellQty, EditableCellSc, updateTableData, autoResetPage: !skipPageReset, isTableValid, getCurrencyDecimals }, useSortBy, usePagination);

    return (
        <>
            <table {...getTableProps()} className="margin_table products_table mb-0 overflow-x-scroll block xl:table">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    {...column.getHeaderProps({ className: (column as any).className })}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps({ className: (cell.column as any).className })}
                                        >
                                            {cell.render("Cell")}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    {footerGroups.map(group => (
                        <tr {...group.getFooterGroupProps()}>
                            {group.headers.map(column => (
                                <td {...column.getFooterProps({ className: (column as any).className })}>
                                    {column.render("Footer")}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
        </>
    );
};

export default MarginCalculationTable;
