import { useMutation } from "@tanstack/react-query";
import client from "../client";

export default function useProfileMutations() {

    // ADD NEW CONTACT
    const storeProfile = useMutation(async (params: any) => {
        return await client.post("customers/profile", params);
    });

    // UPDATE CONTACTS
    const updateProfile = useMutation(async (params: any) => {
        return await client.put("customers/profile", params);
    });

    // DELETE CONTACT
    const deleteProfile = useMutation(async (params: any) => {
        return await client.delete("customers/profile", { params });
    });

    return { storeProfile, updateProfile, deleteProfile };
}
