import client from "../client";
import { useQuery } from "@tanstack/react-query";

const useGrossPriceLisings = () => {
    const getGrossPriceListing = async () => {
        const res = await client.get("reserved-conditions/gross-price-listings");

        return res.data.data.map((priceList: any) => {
            return { ...priceList, label: `${priceList?.code} - ${priceList?.description}` };
        });
    };

    // react query
    const { data: priceListing, isLoading, isFetching } = useQuery(["priceListings"], () => getGrossPriceListing(), {
        refetchOnWindowFocus: false,
        retry: false,
    });

    return { priceListing, isLoading, isFetching };
};

export default useGrossPriceLisings;
