import { useAuthContext } from "../../hooks/use-context/useAuthContext";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from "@reach/tooltip";
import { useTranslation } from "react-i18next";
import { InfoTooltip } from "../commom/InfoTooltip";

import "@reach/tooltip/styles.css";

type AvailabilityTooltipProps = {
    availability_date: Date
};

export const AvailabilityTooltip = ({ availability_date }: AvailabilityTooltipProps) => {
    const { t } = useTranslation();
    const { user } = useAuthContext();

    const dateFormat = new Intl.DateTimeFormat(user?.interface_language_id);

    const template = (
        <div>
            <div className="space-x-1">
                <span>{t("Data")}:</span>
                <span className="font-light">{availability_date ? dateFormat.format(new Date(availability_date)) : ""}</span>
            </div>
        </div>
    );

    return (
        <>

            {
                availability_date ?
                    <Tooltip
                        label={template}
                        style={{
                            backgroundColor: "#F1F5F6",
                            borderRadius: "3px",
                            marginRight: "20px",
                            zIndex: 50
                        }}
                    >
                        <span className="flex items-center">
                            <AiOutlineInfoCircle className="text-sm text-gray-500" />
                        </span>
                    </Tooltip>
                    :
                    <InfoTooltip>{t("In approvvigionamento")}</InfoTooltip>
            }
        </>
    );
};
