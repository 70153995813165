import {Route, Routes} from "react-router-dom";
import {Title} from "../components/Title";
import {PriceList} from "./PriceList";
import Discount from "./Discount";
import {useState} from "react";
import {useAuthContext} from "../hooks/use-context/useAuthContext";
import {Tab, Tabs} from "../components/Tabs";
import GrossPriceList from "./GrossPriceList";
import MarketingPriceList from "./MarketingPriceList";
import RequireAuth from "../auth/RequireAuth";

export default function ReservedConditions() {
    const {can} = useAuthContext();

    const [title, setTitle] = useState<string | null>("");

    const tabs: Tab[] = [
        {title: "Listino personalizzato", to: "/reserved-conditions", isVisibile: can(["can_see_discounted_pricelist"])},
        {title: "Tabella sconti", to: "discounts", isVisibile: can(["can_manage_discount_table"])},
        {title: "Listino prezzi", to: "/reserved-conditions/gross-price-list", isVisibile: can(["can_see_gross_pricelist"])},
        {title: "Listino marketing", to: "marketing", isVisibile: can(["can_see_marketing_pricelist"])},
    ];

    return (
        <div className="py-10 px-8 2xl:px-28">
            <Title variant={Title.variant.primary}>{title}</Title>

            <Tabs tabs={tabs}/>

            <Routes>

                {/* PROFILE */}
                <Route element={<RequireAuth allowedRoles={["can_see_discounted_pricelist"]}/>}>
                    <Route path="/" element={<PriceList setTitle={setTitle}/>}/>
                </Route>

                {/* DICOUNT TABLE */}
                <Route element={<RequireAuth allowedRoles={["can_manage_discount_table"]}/>}>
                    <Route path="discounts" element={<Discount setTitle={setTitle}/>}/>
                </Route>

                {/* GROSS PRICELIST */}
                <Route element={<RequireAuth allowedRoles={["can_see_gross_pricelist"]}/>}>
                    <Route path="/gross-price-list" element={<GrossPriceList setTitle={setTitle}/>}/>
                </Route>

                {/* MARKETING PRICELIST */}
                <Route element={<RequireAuth allowedRoles={["can_see_marketing_pricelist"]}/>}>
                    <Route path="marketing" element={<MarketingPriceList setTitle={setTitle}/>}/>
                </Route>

            </Routes>
        </div>
    );
}
