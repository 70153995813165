import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import { useAuthContext } from "../../../../hooks/use-context/useAuthContext";
import { Item } from "../../../../interfaces/Item";
import { useShippingContext } from "../../../../hooks/use-context/useShippingContext";
import { Tooltip } from "@reach/tooltip";

type AvailableItemsTableProps = {
    items: Item[];
    cart: any;
    expeditionDays: any;
};

const AvailableItemsTable: React.FC<AvailableItemsTableProps> = ({ items, cart, expeditionDays }) => {
    const { t } = useTranslation();

    const { user } = useAuthContext();
    const { shipping_method } = useShippingContext();

    // table data
    const data = useMemo(
        () => {
            return items;
        },
        [items]
    );

    const columns: Array<Column<Item>> = useMemo(
        () => [
            {
                accessor: "code",
                width: 100,
                minWidth: 100,
                Cell: (props) => {
                    return (
                        <img src={`https://resources.rothoblaas.com/eservice-images/product/${props.cell.row.original.code}`} className="zoom max-w-[50px] max-h-[50px] m-auto" alt='' />
                    );
                }
            },
            {
                accessor: "description",
                width: 250,
                minWidth: 250,
                Cell: (props) => {
                    return (
                        <>
                            <div className='font-bold mb-1'>{props.cell.row.original.code.toUpperCase()}</div>
                            <span>{props.cell.row.original.description}</span>
                        </>
                    );
                }
            },
            {
                accessor: "qty",
                width: 250,
                minWidth: 250,
                Cell: (props) => {
                    const qty = props.row.original.qty;

                    const numberFormat = new Intl.NumberFormat(user?.language_id);

                    return (
                        <div className="flex flex-col gap-1">
                            <span className="font-normal">{t("Quantità")}</span>
                            <span>{numberFormat.format(qty) + " " + t("Pezzi")}</span>
                        </div>
                    );
                }
            },
            {
                id: "storageArea",
                width: 150,
                minWidth: 150,
                Cell: (props: any) => {
                    return (
                        <div className="flex flex-col gap-1">
                            <span className="font-normal">{t("Magazzino")}</span>
                            <Tooltip
                                label={props.row.original?.storageArea?.description ?? props.cart?.data?.customer?.technicalSale?.storageArea?.description}
                                style={{
                                    backgroundColor: "#F1F5F6",
                                    borderRadius: "3px",
                                    fontWeight: "300",
                                    marginRight: "20px",
                                    zIndex: 50
                                }}
                            >
                                <span className="cursor-pointer">{props.row.original?.storageArea?.code ?? props.cart?.data?.customer?.technicalSale?.storageArea?.code}</span>
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                id: "deliveryTimes",
                Cell: () => {
                    return (
                        <span>{t("Tempi di consegna da {{min_days}} a {{max_days}} giorni", {
                            min_days: expeditionDays[shipping_method?.name]?.min_days ?? -1,
                            max_days: expeditionDays[shipping_method?.name]?.max_days ?? -1,
                        })}</span>
                    );
                }
            },
        ],
        [t, cart]
    );

    const defaultColumn = useMemo(
        () => ({
            width: "auto",
        }),
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
    } = useTable({ columns, data, defaultColumn, initialState: { pageSize: 100 }, cart }, useSortBy, usePagination);

    return (
        <div>
            {/* <div className="flex items-center gap-x-2 px-6 py-3 border bg-neutral-weaker font-medium text-lime-500">
                <span>{t("Tempi di consegna da {{min_days}} a {{max_days}} giorni", {
                    min_days: expeditionDays[shipping_method?.name]?.min_days ?? -1,
                    max_days: expeditionDays[shipping_method?.name]?.max_days ?? -1,
                })}</span>
            </div> */}
            <div className="flex flex-col justify-center gap-x-2 px-6 py-3 bg-neutral-weaker border">
                <div className="flex gap-3 items-center">
                    <div className="bg-lime-500 w-2 h-2 rounded-full" />
                    <span className="text-sm">{t("Disponibili")}</span>
                </div>
            </div>
            <div className='overflow-y-scroll overflow-visible min-h-min max-h-[304px] border-l border-r border-b'>
                <table {...getTableProps()} className="w-full text-xs font-light">
                    <tbody {...getTableBodyProps()}>
                        {page.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} className="border-b last:border-b-0">
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps({ className: (cell.column as any).className, style: { minWidth: cell.column.minWidth, width: cell.column.width } })} className="p-4">
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AvailableItemsTable;
