import { useEffect, useState } from "react";
import { Button } from "../components/Button";
import { useTranslation } from "react-i18next";
import Table from "../components/Table";
import { FaCheck } from "react-icons/fa6";
import { FiX } from "react-icons/fi";
import { useForm } from "react-hook-form";
import useProfileMutations from "../api/profile/useProfileMutations";
import { Loader } from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { useAuthContext } from "../hooks/use-context/useAuthContext";
import { FaTrash } from "react-icons/fa";
import { Modal } from "../components/commom/Modal";
import { NewContactModal } from "../components/Profile/NewContactModal";

export default function Contacts({ data: profile }: any) {
    const { t } = useTranslation();
    const { roles } = useAuthContext();
    const navigate = useNavigate();

    // state
    const [isEditing, setIsEditing] = useState(false);
    const [defaultValues, setDefaultValues] = useState<any>({});
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // react hook form
    const { handleSubmit, register, reset, formState: { isDirty, dirtyFields } } = useForm({ defaultValues: defaultValues });

    // react query
    const { updateProfile, deleteProfile } = useProfileMutations();

    useEffect(() => {
        if (!roles(["CLIENT", "GROUP_LEADER", "SUB_CLIENT"])) {
            setHiddenColumns(["delete"]);
        }
    }, []);

    useEffect(() => {
        if (profile?.data?.contacts?.length > 0) {
            const values: any = {};
            profile?.data?.contacts?.forEach((contact: any) => {
                values[contact.id] = {
                    flag_confirmationorder: contact.flag_confirmationorder,
                    flag_customerinvoice: contact.flag_customerinvoice,
                    flag_deliveryslip: contact.flag_deliveryslip,
                    flag_tracking: contact.flag_tracking,
                    contact_email: contact.email
                };
            });
            setDefaultValues(values);
            reset(values);
        }
    }, [profile, reset]);

    const columns = [
        {
            Header: t("").toString(),
            accessor: "client",
            className: "text-left px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div className="flex flex-col py-5">
                        <div className="space-x-2">
                            {
                                props?.row?.original?.givenname &&
                                <span className="font-normal">
                                    {props?.row?.original?.givenname}
                                </span>
                            }
                            <span className="font-normal">
                                {props?.row?.original?.surname}
                            </span>
                        </div>
                        <div className="flex gap-2 items-center py-1">
                            <FaPhoneAlt className="w-4 h-4" />
                            <span>{props?.row?.original?.phone}</span>
                        </div>
                        <div className="flex gap-2 items-center">
                            <IoIosMail className="w-5 h-5" />
                            <span>{props?.row?.original?.email}</span>
                        </div>
                    </div>
                );
            },
        },
        {
            Header: t("Conferma ordine").toString(),
            accessor: "order",
            className: "text-center px-4 py-2 border-b",
            Cell: (props: any) => {

                return (
                    <div className="flex justify-center items-center">
                        {isEditing ?
                            <input
                                {...register(`${props?.row?.original?.id}.flag_confirmationorder`)}
                                type="checkbox"
                                className="accent-black"
                            />
                            :
                            props?.row?.original?.flag_confirmationorder ?
                                <FaCheck className="text-green-600 w-5 h-5" />
                                :
                                <FiX className="text-red-500 w-7 h-7" />
                        }
                    </div>
                );
            },
        },
        {
            Header: t("Fatture").toString(),
            accessor: "invoices",
            className: "text-center px-4 py-2 border-b",
            Cell: (props: any) => {

                return (
                    <div className="flex justify-center items-center">
                        {isEditing ? (
                            <input
                                {...register(`${props?.row?.original?.id}.flag_customerinvoice`)}
                                type="checkbox"
                                className="accent-black"
                            />
                        ) : props?.row?.original?.flag_customerinvoice ? (
                            <FaCheck className="text-green-600 w-5 h-5" />
                        ) : (
                            <FiX className="text-red-500 w-7 h-7" />
                        )}
                    </div>
                );
            },
        },
        {
            Header: t("Bolle").toString(),
            accessor: "note",
            className: "text-center px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div className="flex justify-center items-center">
                        {isEditing ? (
                            <input
                                {...register(`${props?.row?.original?.id}.flag_deliveryslip`)}
                                type="checkbox"
                                className="accent-black"
                            />
                        ) : props?.row?.original?.flag_deliveryslip ? (
                            <FaCheck className="text-green-600 w-5 h-5" />
                        ) : (
                            <FiX className="text-red-500 w-7 h-7" />
                        )}
                    </div>
                );
            },
        },
        {
            Header: t("Tracking").toString(),
            accessor: "tracking",
            className: "text-center px-4 py-2 border-b",
            Cell: (props: any) => {
                return (
                    <div className="flex justify-center items-center">
                        {isEditing ? (
                            <input
                                {...register(`${props?.row?.original?.id}.flag_tracking`)}
                                type="checkbox"
                                className="accent-black"
                            />
                        ) : props?.row?.original?.flag_tracking ? (
                            <FaCheck className="text-green-600 w-5 h-5" />
                        ) : (
                            <FiX className="text-red-500 w-7 h-7" />
                        )}
                    </div>
                );
            },
        },
        {
            Header: t("Elimina").toString(),
            accessor: "delete",
            className: "border-b",
            Cell: (props: any) => {
                const [open, setOpen] = useState(false);

                const handleDeleteContact = async (contact: any) => {
                    setIsLoading(true);
                    await deleteProfile.mutateAsync({ name: contact?.surname, email: contact?.email, type: "DELETE", customer_code: profile?.data?.code, id: props.row?.original?.id });
                    setIsLoading(false);
                    setOpen(false);
                    navigate("/profile-sent");
                }

                return (
                    <>
                        {
                            open &&
                            <Modal title={t("Richiedi eliminazione contatto")} open={open} onClose={setOpen}>
                                <p>{t("Vuoi eliminare questo contatto?")}</p>
                                <div className="text-right space-x-2 mt-4">
                                    <Button variant={Button.variant.text} onClick={() => setOpen(false)}>{t("Annulla")}</Button>
                                    <Button variant={Button.variant.primary} onClick={() => handleDeleteContact(props?.row?.original)}>{t("Procedi")}</Button>
                                </div>
                            </Modal >
                        }

                        <div className="flex justify-center">
                            <FaTrash
                                className="cursor-pointer hover:text-secondary"
                                onClick={() => setOpen(true)}
                            />
                        </div>
                    </>
                );
            }
        },
    ];

    const filteredColumns = roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]) ? columns : columns.filter((column: any) => column?.accessor !== "delete");

    const onSubmit = async (data: any) => {
        if (isDirty) {
            const mappedData: any = {};
            Object.keys(dirtyFields).map((field: any) => {
                Object.keys(dirtyFields[field]).map((nestedField) => {
                    if (!mappedData[field]) {
                        mappedData[field] = {};
                    }

                    mappedData[field][nestedField] = data[field][nestedField];
                    mappedData[field]["contact_email"] = data[field]["contact_email"];
                });
            });

            await updateProfile.mutateAsync({ customer_code: profile?.data?.code, contacts: mappedData, type: "UPDATE" });
            setIsEditing(false);
            navigate("/profile-sent");
        }
    };

    return (
        <>
            {updateProfile.isLoading && <Loader />}
            {isLoading && <Loader />}

            {/* ADD NEW CONTACT MODAL */}
            {
                isContactModalOpen &&
                <NewContactModal customerCode={profile?.data?.code} isOpen={isContactModalOpen} setIsOpen={setIsContactModalOpen} setIsLoading={setIsLoading} />
            }

            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                    <Table
                        label="Contatti"
                        data={profile?.data?.contacts ?? []}
                        columns={filteredColumns}
                    />
                    {
                        roles(["CLIENT", "SUB_CLIENT", "GROUP_LEADER"]) ?
                            <div className="flex justify-end w-full mt-6 gap-4">
                                <Button
                                    onClick={() => setIsContactModalOpen(true)}
                                    type="button"
                                >
                                    {t("Aggiungi nuovo")}
                                </Button>

                                {
                                    isEditing &&
                                    <Button
                                        isDisabled={!isDirty}
                                    >
                                        Invia richiesta modifica
                                    </Button>
                                }

                                {
                                    !isEditing &&
                                    <Button
                                        onClick={() => setIsEditing(true)}
                                        type="button"
                                    >
                                        {t("Modifica")}
                                    </Button>
                                }
                            </div>
                            :
                            <div className="flex justify-end mt-6"><span>{t("Per modifica, aggiunta o eliminazione di contatti vi preghiamo di utilizzare il CRM")}</span></div>
                    }
                </fieldset>
            </form>
        </>
    );
}
