import { useTranslation } from "react-i18next";
import { Modal } from "../commom/Modal";
import { Button } from "../Button";
import { useForm } from "react-hook-form";
import useProfileMutations from "../../api/profile/useProfileMutations";
import { useNavigate } from "react-router-dom";

type Props = {
    isOpen: boolean,
    setIsOpen: any,
    customerCode: string,
    setIsLoading: any,
}


export const NewContactModal = ({ isOpen, setIsOpen, customerCode, setIsLoading }: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // react hook form
    const { handleSubmit, register } = useForm();

    // react query
    const { storeProfile } = useProfileMutations();

    const onSubmit = async (data: any) => {
        setIsLoading(true);
        await storeProfile.mutateAsync({ contact: data, type: "STORE", customer_code: customerCode });
        setIsOpen(false);
        setIsLoading(false);
        navigate("/profile-sent");
    };


    return <Modal title={t("Richiedi aggiunta contatto")} open={isOpen} onClose={setIsOpen}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <div className="space-y-2">
                    <div>
                        <label htmlFor="name" className="font-medium">{t("Nome")}</label>
                        <input type="text" {...register('name')} required />
                    </div>
                    <div>
                        <label htmlFor="email" className="font-medium">{t("Email")}</label>
                        <input type="email" {...register('email')} required />
                    </div>
                    <div>
                        <label htmlFor="tel" className="font-medium">{t("Telefono")}</label>
                        <input type="tel" {...register('tel')} required />
                    </div>
                    <div><span className="font-medium">{t("Permessi")}</span></div>
                    <div className="grid grid-cols-2 gap-2">
                        <label className="flex gap-2">
                            <input type="checkbox" className="accent-black" {...register('flag_confirmationorder')} />
                            {t("Conferma ordine")}
                        </label>
                        <label className="flex gap-2">
                            <input type="checkbox" className="accent-black" {...register('flag_customerinvoice')} />
                            {t("Fatture")}
                        </label>
                        <label className="flex gap-2">
                            <input type="checkbox" className="accent-black" {...register('flag_deliveryslip')} />
                            {t("Bolle")}
                        </label>
                        <label className="flex gap-2">
                            <input type="checkbox" className="accent-black" {...register('flag_tracking')} />
                            {t("Tracking")}
                        </label>
                    </div>
                </div>
                <div className="text-right space-x-2 mt-4">
                    <Button variant={Button.variant.text} onClick={() => setIsOpen(false)}>{t("Annulla")}</Button>
                    <Button variant={Button.variant.primary}>{t("Procedi")}</Button>
                </div>
            </fieldset>
        </form>
    </Modal >;
}
