import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImFilesEmpty } from "react-icons/im";
import { Title } from "../components/Title";
import { Loader } from "../components/Loader";
import Pagination from "../components/Pagination";
import useInvoices from "../api/invoices/useInvoices";
import InvoicesSearchBar, { InvoicesSearchParams } from "../components/invoices/InvoicesSearchBar";
import { InvoicesTable } from "../components/invoices/InvoicesTable";
import SubRowAsync from "../components/carts/SubRowAsync";
import { useSearchParams } from "react-router-dom";
import { Section } from "../components/Section";
import { useAuthContext } from "../hooks/use-context/useAuthContext";

type searchParams = {
    "filter[number]"?: string,
    "filter[item_code]"?: string,
    "filter[customer_id]"?: string,
    "filter[customer_name]"?: string,
    "filter[status]"?: string,
    "filter[grossamount]"?: string,
    "filter[technical_sale]"?: string,
    "filter[min_date]"?: string,
    "filter[max_date]"?: string,
    "sort"?: string
};

const Invoices = () => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { roles } = useAuthContext();
    const initialPage = parseInt(searchParams.get("page") || "1", 10);

    // state
    const [currentPage, setCurrentPage] = useState(initialPage);
    const [params, setParams] = useState<InvoicesSearchParams>({});
    const [canFetch, setCanFetch] = useState<boolean>(roles(["CLIENT", "CLIENT_LIMITED", "TS"]));

    // react query
    const { invoices, isLoading, isFetching } = useInvoices({ page: currentPage, ...params }, canFetch);

    // SUB ROW RENDER FUNCTION
    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <SubRowAsync row={row} endpoint="invoices" />
        ),
        []
    );

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    // reset page when params change
    useEffect(() => {
        const tmpParams: any = {};

        searchParams.forEach((value: any, key: any) => {
            tmpParams[key] = value;
        });

        setParams(tmpParams);

        const pageFromParams = parseInt(searchParams.get("page") || "1", 10);
        if (pageFromParams !== currentPage) {
            setCurrentPage(pageFromParams);
        }
    }, [searchParams]);

    return (
        <div className="py-10 px-8 2xl:px-28">
            {(isLoading || isFetching) && canFetch && <Loader />}

            <Title variant={Title.variant.primary}>{t("Le mie fatture")}</Title>

            <Section title={"Cerca fattura"} className="mb-10">
                <InvoicesSearchBar setSearchParams={setSearchParams} setCanFetch={setCanFetch} />
            </Section>

            <>
                {
                    invoices?.data?.length > 0 ?
                        <>
                            <InvoicesTable
                                renderRowSubComponent={renderRowSubComponent}
                                items={invoices?.data}
                                setParams={setParams}
                            />
                            <Pagination
                                currentPage={currentPage}
                                totalCount={invoices?.meta.total}
                                pageSize={10}
                                onPageChange={handlePageChange}
                            />
                        </>
                        :
                        <div className="h-96 flex flex-col items-center justify-center space-y-6">
                            <span className="text-9xl text-gray-200"><ImFilesEmpty /></span>
                            {canFetch ? (
                                <p className="text-2xl text-gray-600">{t("Nessuna fattura trovata")}</p>
                            ) : (
                                <p className="text-2xl text-gray-600">{t("Imposta un filtro per effettuare una ricerca.")}</p>
                            )}
                        </div>
                }
            </>
        </div>
    );
};

export default Invoices;
