import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Button";
import debounce from "lodash.debounce";
import { ItemSelect } from "../../commom/ItemSelect";
import client from "../../../api/client";
import { URLSearchParamsInit } from "react-router-dom";
import Select from "react-select";
import { useSelectStyles } from "../../../hooks/useSelectStyles";

type MarketingSearchBarProps = {
    setSearchParams: (nextInit: URLSearchParamsInit, navigateOptions?: {
        replace?: boolean | undefined;
        state?: any;
    } | undefined) => void,
    setCanFetch: React.Dispatch<React.SetStateAction<boolean>>,
};

export type marketingSearchParams = {
    "filter[item_code]"?: string,
    "filter[organization]"?: string,
    "filter[priceclassification]"?: string,
    "filter[language]"?: string,
    "filter[brand]"?: string,
    "sort"?: string
};

type formData = {
    "filter[item_code]"?: string,
    "filter[organization]"?: string,
    "filter[priceclassification]"?: string,
    "filter[language]"?: string,
    "filter[brand]"?: string,
};

export default function MarketingPriceListSearchBar({ setSearchParams, setCanFetch }: MarketingSearchBarProps) {
    const { t } = useTranslation();

    // hooks
    const { CustomStyles } = useSelectStyles();

    // refs
    const itemCodeSelect = useRef<any>();

    // state
    const [formData, setFormData] = useState<formData>({
        "filter[organization]": "IT001"
    });
    const [priceclassification, setPriceclassification] = useState<any>([]);
    const [organization, setOrganization] = useState<any>([]);
    const [languages, setLanguages] = useState<any>([]);
    const brands = [
        { label: "RB", value: { code: "RB" } },
        { label: "HT", value: { code: "HT" } },
        { label: "SAFE", value: { code: "SAFE" } },
    ];

    // ITEM CODE
    const handleItemSearchChange = (inputValue: any, action: any) => {
        if (action?.action === "select-option") {
            setFormData((prevFormData: any) => {
                return { ...prevFormData, [action?.name]: inputValue?.value?.code };
            });
        } else {
            const newForm = { ...formData };
            delete newForm[action?.name as keyof formData];
            setFormData(newForm);
        }
    };

    //GET ITEM
    const getProducts = useCallback(async (value: string) => {
        const params = {
            "filter[search]": value,
        };

        const res = await client.get("search-marketing-products", { params: params });

        return res.data.data.map((item: any) => {
            return { label: `${item.code} - ${item.description}`, value: { code: item?.code } };
        });
    }, []);

    //GET PRICECLASSIFICATIONS
    const getPriceclassifications = async (value: string) => {
        const params = {
            "filter[search]": value,
        };

        const res = await client.get("priceclassifications", { params: params });

        return res.data.data.map((item: any) => {
            return { label: item.description, value: { code: item?.code } };
        });
    };

    //GET ORGANIZATIONS
    const getOrganizations = async () => {
        const res = await client.get("organizations");

        return res.data.data.map((item: any) => {
            return { label: `${item.code} - ${item.description}`, value: { code: item?.code } };
        });
    };

    //GET LANGUAGES
    const getLanguages = async () => {
        const res = await client.get("language/semiramis_available_locales");

        return res.data.data;
    };

    const loadItemsOptions = useMemo(() => {
        return debounce((value: any, callback: any) => {
            getProducts(value).then((options) => callback(options));
        }, 1000);
    }, [getProducts]);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        // return to pagination 1 when the user uses a filter
        const updatedParams = { ...formData, page: "1" };

        setCanFetch(true);
        setSearchParams(updatedParams);
    };

    const resetForm = () => {
        itemCodeSelect.current.clearValue();

        setCanFetch(true);
        setFormData({});
    };

    useEffect(() => {
        getPriceclassifications("S-H0-46-").then(el => setPriceclassification(el));
        getOrganizations().then(el => setOrganization(el));
        getLanguages().then(el => setLanguages(el));
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <fieldset className="m-0">
                    <legend className="sr-only"><span>{t("Ricerca")}</span></legend>

                    <div className="space-y-6">
                        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 whitespace-nowrap">
                            <div>
                                <label>{t("Codice articolo")}</label>
                                <ItemSelect
                                    ref={itemCodeSelect}
                                    name="filter[item_code]"
                                    loadItemsOptions={loadItemsOptions}
                                    handleChange={handleItemSearchChange}
                                />
                            </div>
                            <div>
                                <label>{t("Consociata")}</label>
                                <Select
                                    name="filter[organization]"
                                    styles={CustomStyles}
                                    options={organization}
                                    placeholder={t("Scegli una consociata")}
                                    onChange={handleItemSearchChange}
                                    escapeClearsValue
                                    isClearable
                                    value={organization?.filter((option: any) => option.value.code === formData["filter[organization]"])}
                                />
                            </div>
                        </div>
                        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4 whitespace-nowrap">
                            <div>
                                <label>{t("Brand")}</label>
                                <Select
                                    name="filter[brand]"
                                    styles={CustomStyles}
                                    options={brands}
                                    placeholder={t("Scegli un brand")}
                                    onChange={handleItemSearchChange}
                                    escapeClearsValue
                                    isClearable
                                    value={brands?.filter((option: any) => option.value.code === formData["filter[brand]"])}
                                />
                            </div>
                            <div>
                                <label>{t("Classificazione articolo")}</label>
                                <Select
                                    name="filter[priceclassification]"
                                    styles={CustomStyles}
                                    options={priceclassification}
                                    placeholder={t("Scegli una classificazione")}
                                    onChange={handleItemSearchChange}
                                    escapeClearsValue
                                    isClearable
                                    value={priceclassification?.filter((option: any) => option.value.code === formData["filter[priceclassification]"])}
                                />
                            </div>
                            <div>
                                <label>{t("Lingua")}</label>
                                <Select
                                    name="filter[language]"
                                    styles={CustomStyles}
                                    options={languages}
                                    placeholder={t("Scegli la lingua")}
                                    onChange={handleItemSearchChange}
                                    escapeClearsValue
                                    isClearable
                                    value={languages.filter((option: any) => option?.value?.code === formData["filter[language]"])}
                                />
                            </div>
                        </div>
                        <div className="w-1/3 flex gap-x-2">
                            <Button variant={Button.variant.primary} size={Button.size.full}>{t("Cerca")}</Button>
                            <Button
                                variant={Button.variant.text}
                                className="text-xs py-0 flex-1 whitespace-nowrap"
                                icon={Button.icon.reset}
                                onClick={resetForm}
                            >
                                {t("reset ricerca")}
                            </Button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </>
    );
}
