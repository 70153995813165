import { Dialog } from "@reach/dialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";
import { ClipLoader } from "react-spinners";
import { Title } from "../../../Title";
import { Loader } from "../../../Loader";
import { useCartItemsMutations } from "../../../../api/carts/useCartItemsMutations";
import { Tabs, Tab } from "../../../Tabs";
import { AlternativeWarehouse } from "./AlternativeWarehouse";
import useAlternativeWarehouse from "../../../../api/alternative-warehouse/useAlternativeWarehouse";
import useOfferItemsMutations from "../../../../api/offers/useOfferItemsMutations";
import { ReplaceProduct } from "./ReplaceProduct";

type ChangeProductModalProps = {
    open: boolean;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    alternativeItems: any;
    cart: any;
    row: any;
};

const REPLACE_PRODUCT_TAB = 0;
const ALTERNATIVE_WAREHOUSE_TAB = 1;

export default function ChangeProductModal({ open, onClose, alternativeItems, ...props }: ChangeProductModalProps) {
    const { t } = useTranslation();
    const { cart, row } = props;

    const isOffer = !!cart.data.offer_state;
    const unavailableQuantity = row.original.availabilityInfo?.unavailableQuantity;

    const { replaceProduct } = useCartItemsMutations();
    const { replaceOfferProduct } = useOfferItemsMutations();

    // state
    const [selectedAlternative, setSelectedAlternative] = useState<any>(null);
    const [alternativeItemQty, setAlternativeItemQty] = useState<any>(0);
    const [isWarehouseSelected, setIsWarehouseSelected] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<number>(0);

    // useQuery
    const { data: warehouse, isLoading, isFetching } = useAlternativeWarehouse({ cartId: cart?.data?.id, cartItem: row.original.id });

    const tabs: Tab[] = [
        { title: "Sostituisci prodotto", to: "", isVisibile: true },
        { title: "Magazzino alternativo", to: "", isVisibile: true },
    ];

    const closeModal = () => {
        setSelectedAlternative(null);
        setAlternativeItemQty(0);
        setIsWarehouseSelected(false);
        onClose(false);
        setActiveTab(0);
    };

    return (
        <Dialog isOpen={open} onDismiss={closeModal} style={{ width: 700 }} >
            {replaceProduct.isLoading && <Loader />}

            <div className='space-y-6'>
                {
                    (!alternativeItems || isLoading || isFetching) ?
                        <div className='flex justify-center items-center'>
                            <ClipLoader
                                color={"black"}
                                loading={true}
                                size={100}
                                aria-label="Loading Spinner"
                            />
                        </div>
                        : <>
                            <div className="flex flex-col gap-4">
                                <div className='flex justify-between items-center'>
                                    <Title variant={Title.variant.secondary}>{t("Prodotto non disponibile")}</Title>
                                    <MdClose className='text-lg cursor-pointer hover:text-secondary' onClick={closeModal} />
                                </div>
                                <span className="font-light">{t("Il prodotto selezionato non è attualmente disponibile nella quantità indicata presso il nostro magazzino principale. Puoi decidere di ricevere il prodotto da un magazzino alternativo oppure scegliere un prodotto correlato.")}</span>
                            </div>


                            <Tabs tabs={tabs} variant={"index"} activeTab={activeTab} setActiveTab={setActiveTab} />

                            {/* REPLACE PRODUCT TAB CONTENT */}
                            {
                                activeTab === REPLACE_PRODUCT_TAB &&
                                <ReplaceProduct
                                    alternativeItems={alternativeItems}
                                    selectedAlternative={selectedAlternative}
                                    setSelectedAlternative={setSelectedAlternative}
                                    alternativeItemQty={alternativeItemQty}
                                    setAlternativeItemQty={setAlternativeItemQty}
                                    closeModal={closeModal}
                                    cartId={cart?.data?.id}
                                    productId={row.original.id}
                                    replaceProduct={isOffer ? replaceOfferProduct : replaceProduct}
                                />
                            }


                            {/* ALTERNATIVE WAREHOUSE TAB CONTENT */}
                            {
                                activeTab === ALTERNATIVE_WAREHOUSE_TAB &&
                                <AlternativeWarehouse
                                    name={warehouse?.data?.description}
                                    isWarehouseSelected={isWarehouseSelected}
                                    setIsWarehouseSelected={setIsWarehouseSelected}
                                    closeModal={closeModal}
                                    cartId={cart?.data?.id}
                                    productId={row.original.id}
                                    storageareaId={warehouse?.data?.id}
                                    unavailableQuantity={unavailableQuantity}
                                />
                            }

                        </>
                }
            </div>
        </Dialog>
    );
}
