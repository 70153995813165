import { useTranslation } from "react-i18next";
import { useAuthContext } from "../../../hooks/use-context/useAuthContext";
import { getCurrencyDecimals } from "../../../utils/getCurrencyDecimals";
import { Title } from "../../Title";

type CartCostsSummaryProps = {
    summary: any,
    data: any
};

export default function CartCostsSummary({ summary, data }: CartCostsSummaryProps) {
    const { user } = useAuthContext();
    const { t } = useTranslation();

    const currency = data?.data?.isCustomerProposal ? data?.data?.currency_id : data?.data?.customer?.currency;
    const numberFormat = currency ? new Intl.NumberFormat(data?.data?.customer?.language_id ?? user?.language_id, { style: "currency", currency: currency, minimumFractionDigits: getCurrencyDecimals(currency), maximumFractionDigits: getCurrencyDecimals(currency) }) : null;

    return (
        <>
            {/* riepilogo ordine  */}
            <div className="border">
                {/* container */}
                <div className="p-6 text-sm font-light">
                    <Title variant={Title.variant.secondary} className="pb-4">{t("Riepilogo")}</Title>
                    {/* top */}
                    <div>
                        <div className="border-b space-y-1 pb-3">
                            <div className="flex justify-between pr-3">
                                <span>{t("Articoli")}:</span>
                                <span className="font-normal">{numberFormat?.format(summary?.total_products_without_iva)}</span>
                            </div>
                            {
                                (summary?.is_freightcost_calculated || summary?.shipping_method_without_iva !== null) &&
                                <div className="flex justify-between pr-3">
                                    <span>{t("Costi spedizioni")}:</span>
                                    <span className="font-normal">{numberFormat?.format(summary?.shipping_method_without_iva)}</span>
                                </div>
                            }
                            {
                                summary?.total_services_without_iva > 0 &&
                                <div className="flex justify-between pr-3">
                                    <span>{t("Costo dei servizi")}:</span>
                                    <span className="font-normal">{numberFormat?.format(summary?.total_services_without_iva)}</span>
                                </div>
                            }
                        </div>

                        <div className="space-y-1 pt-3">
                            <div className="flex justify-between pr-3">
                                <span>{t("Totale ordine")}:</span>
                                <span className="font-normal">{numberFormat?.format(summary?.total_order_without_iva)}</span>
                            </div>
                            {
                                summary?.multiple_iva && Object.keys(summary?.multiple_iva).length > 1 &&
                                <div className="flex justify-between pr-3">
                                    <span>{t("IVA totale")}:</span>
                                    <span className="font-normal">{numberFormat?.format(summary?.total_iva)}</span>
                                </div>
                            }
                            {
                                summary?.multiple_iva[0] !== 0 && summary?.multiple_iva && summary?.tax_calculation && Object.keys(summary?.multiple_iva).map((key) => {
                                    if (Object.keys(summary?.multiple_iva).length > 1) {
                                        return (
                                            <div key={key} className="flex justify-between pr-3">
                                                <span>{" - "}{t("IVA ({{percentage}}%)", { percentage: parseFloat(key) })}:</span>
                                                <span className="font-normal">{numberFormat?.format(summary?.multiple_iva[key])}</span>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div key={key} className="flex justify-between pr-3">
                                                <span>{t("IVA totale ({{percentage}}%)", { percentage: parseFloat(key) })}:</span>
                                                <span className="font-normal">{numberFormat?.format(summary?.multiple_iva[key])}</span>
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                    </div>

                </div>
                {/* bottom */}
                <div className="bg-tertiary px-6 py-4">
                    <div className="flex justify-between pr-3">
                        <span>{t("Totale")}:</span>
                        <span>{numberFormat?.format(summary?.total_order)}</span>
                    </div>
                </div>
            </div>
        </>
    );
}
