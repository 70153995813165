import { useQuery } from "@tanstack/react-query";
import client from "../client";

type UnitOfMeasuresParams = {
    'filter[special]'?: boolean;
}

export default function useUnitOfMeasures(params: UnitOfMeasuresParams, enabled: boolean = true) {
    const fetchUnitOfMeasures = async () => {
        const res = await client.get(`unit-of-measures`, { params });
        return res.data;
    };

    // GET SINGLE ORDER
    const { data: unitOfMeasures, isLoading, isFetching } = useQuery(["unit-of-measures", params],
        () => fetchUnitOfMeasures(),
        {
            refetchOnWindowFocus: false,
            enabled
        }
    );

    return { unitOfMeasures, isLoading, isFetching };
}
