import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import ComplaintsSearchBar, { ComplaintsSearchParams } from "../components/assistance/complaints/ComplaintsSearchBar";
import SubRowAsync from "../components/carts/SubRowAsync";
import { Loader } from "../components/Loader";
import Pagination from "../components/Pagination";
import { ComplaintsTable } from "../components/assistance/complaints/ComplaintsTable";
import { ImFilesEmpty } from "react-icons/im";
import useSemiramisComplaints from "../api/semiramis-complaints/useSemiramisComplaints";
import { Section } from "../components/Section";

export default function ComplaintList() {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [params, setParams] = useState<ComplaintsSearchParams>({});
    const initialPage = parseInt(searchParams.get("page") || "1", 10);

    // state
    const [currentPage, setCurrentPage] = useState(initialPage);

    // react query
    const { semiramisComplaints, isLoading, isFetching } = useSemiramisComplaints({ page: currentPage, ...params });
    // const { data: complaint, isLoading, isFetching } = useComplaintTypes();

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    };

    useEffect(() => {
        const tmpParams: any = {};

        searchParams.forEach((value: any, key: any) => {
            tmpParams[key] = value;
        });

        setParams(tmpParams);

        const pageFromParams = parseInt(searchParams.get("page") || "1", 10);
        if (pageFromParams !== currentPage) {
            setCurrentPage(pageFromParams);
        }
    }, [searchParams]);

    const renderRowSubComponent = useCallback(
        ({ row }) => (
            <SubRowAsync row={row} endpoint="semiramis-complaints" />
        ),
        []
    );

    return <>
        {(isLoading || isFetching) && <Loader />}

        <Section title="Cerca reclamo" className="mb-10">
            <ComplaintsSearchBar setSearchParams={setSearchParams} />
        </Section>

        <>
            {
                semiramisComplaints?.data?.length > 0 ?
                    <>
                        <ComplaintsTable
                            items={semiramisComplaints?.data}
                            setParams={setParams}
                            renderRowSubComponent={renderRowSubComponent}
                        />
                        <Pagination
                            currentPage={currentPage}
                            totalCount={semiramisComplaints?.meta?.total}
                            pageSize={10}
                            onPageChange={handlePageChange}
                        />
                    </>
                    :
                    <div className="h-96 flex flex-col items-center justify-center space-y-6">
                        <span className="text-9xl text-gray-200"><ImFilesEmpty /></span>
                        <p className="text-2xl text-gray-600">{t("Nessun reclamo trovato")}</p>
                    </div>
            }
        </>
    </>;
}
